import {useTabletAuthStore} from '~/store/tabletAuthStore'

export default defineNuxtRouteMiddleware(async (to, _) => {
    const cookieKey = to.path.startsWith('/tablet') ? 'tablet_token' : 'token'

    const token = useCookie(cookieKey).value
    if (token === undefined) {
        return navigateTo('/tablet/login')
    }

    const authStore = useTabletAuthStore()
    const board = await authStore.getBoard()

    if (!board) {
        return navigateTo('/tablet/login')
    }
})
